<div class="block">
    @if(label)
    {
        <p class="text-base opacity-60">{{ label() }}</p>
    }
    <ngx-file-drop
        [showBrowseBtn]="false"
        browseBtnLabel=""
        browseBtnClassName=""
        dropZoneLabel=""
        dropZoneClassName="relative border-2 border-dashed rounded"
        contentClassName="flex flex-col justify-center items-center p-4 min-h-32"
        [directory]="false"
        [accept]="acceptType()"
        [disabled]="isDisabled()"
        [multiple]="isMultiple()"
        (onFileDrop)="fileDrop($event)"
        (onFileOver)="fileOver($event)"
        (onFileLeave)="fileLeave($event)"
    >
        <ng-template
            ngx-file-drop-content-tmp
            let-openFileSelector="openFileSelector"
        >
            @if(fileUploadButtonTemplateDirective)
            {
                <ng-container
                    *ngTemplateOutlet="fileUploadButtonTemplateDirective.templateRef; context: { $implicit: openFileSelector }"
                >
                </ng-container>
            }
            @else
            {
                <button
                    mat-flat-button
                    color="accent"
                    class="absolute -top-3 right-2 h-6 min-h-6 max-h-6 px-4 text-xs"
                    type="button"
                    (click)="openFileSelector()"
                >
                    <mat-icon
                        [svgIcon]="'mat_solid:upload'"
                        class="icon-size-4 mr-2"
                    >
                    </mat-icon>
                    {{ btnLabel() }}
                </button>
            }
            @if(fileUploadContentTemplateDirective)
            {
                <ng-container
                    *ngTemplateOutlet="fileUploadContentTemplateDirective.templateRef;"
                >
                </ng-container>
            }
            @else
            {
                @if(files().length)
                {
                    @for (file of files(); track file.id)
                    {
                        <div class="flex justify-between items-center w-full py-1">
                            @if (fileIconTemplateDirective)
                            {
                                <ng-container
                                    *ngTemplateOutlet="fileIconTemplateDirective.templateRef; context: { $implicit: file }"
                                >
                                </ng-container>
                            }
                            @else
                            {
                                <mat-icon
                                    svgIcon="mat_solid:description"
                                    class="icon-size-5 mr-2"
                                >
                                </mat-icon>
                            }
                            <p
                                [matTooltip]="file[fileDisplay()]"
                                class="w-full mr-2 text-md truncate cursor-default">
                                {{ file[fileDisplay()] }}
                            </p>
                            <button
                                mat-mini-fab
                                aria-label="Download file"
                                color="primary"
                                class="w-6 h-6 min-h-6 max-h-6 cursor-pointer mr-2"
                                (click)="fileDownload(file)"
                            >
                                <mat-icon
                                    [svgIcon]="'mat_solid:download'"
                                    class="icon-size-4"
                                >
                                </mat-icon>
                            </button>
                            <button
                                mat-mini-fab
                                aria-label="Delete file"
                                color="warn"
                                class="w-6 h-6 min-h-6 max-h-6 cursor-pointer"
                                (click)="fileRemove(file)"
                            >
                                <mat-icon
                                    [svgIcon]="'mat_solid:delete'"
                                    class="icon-size-4"
                                >
                                </mat-icon>
                            </button>
                        </div>
                    }
                }
                @else
                {
                    <p class="text-base text-secondary text-center">{{ dropLabel() }}</p>
                }
            }
        </ng-template>
    </ngx-file-drop>
</div>