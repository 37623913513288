<div class="flex flex-col flex-1 overflow-hidden">
    <div class="grid-header">

        <div class="flex">
            <!-- left position custom header template -->
            <ng-container *ngFor="let gridCustomHeader of gridCustomHeadersTemplate | filterGridCustomHeaderTemplatesPositionPipe:'left'">
                <ng-container
                    *ngTemplateOutlet="gridCustomHeader.templateRef"
                ></ng-container>
            </ng-container>
        </div>

        <div class="flex">
            <!-- beforeGridButtons position custom header template -->
            <ng-container *ngFor="let gridCustomHeader of gridCustomHeadersTemplate | filterGridCustomHeaderTemplatesPositionPipe:'beforeGridButtons'">
                <ng-container
                    *ngTemplateOutlet="gridCustomHeader.templateRef"
                ></ng-container>
            </ng-container>

            <!-- search component -->
            <au-grid-search
                *ngIf="hasSearch"
                [searchState]="gridState.search"
                (searchChange)="handleSearchStateChange($event)"
                (search)="handleSearch($event)"
                class="mr-2"
            >
            </au-grid-search>

            <button
                *ngIf="hasExportButton"
                mat-icon-button
                class="mr-2"
                type="button"
                [matMenuTriggerFor]="menuExport"
            >
                <mat-icon>
                    file_download
                </mat-icon>
                <span class="cdk-visually-hidden">
                    Export menu to excel and CSV
                </span>
            </button>
            <mat-menu #menuExport="matMenu">
                <button
                    mat-menu-item
                    type="button"
                    (click)="handleExport($event, exportFormat.EXCEL)"
                >
                  <mat-icon>functions</mat-icon>
                  <span>Excel</span>
                </button>
                <button
                    mat-menu-item
                    type="button"
                    (click)="handleExport($event, exportFormat.CSV)"
                >
                  <mat-icon>table_view</mat-icon>
                  <span>CSV</span>
                </button>
            </mat-menu>

            <!-- filter dialog -->
            <button
                *ngIf="hasFilterButton"
                mat-icon-button
                class="mr-2"
                type="button"
                (click)="handleFiltersDialog()"
            >
                <mat-icon
                    [matBadge]="gridState.columnFilters?.length"
                    [matBadgeHidden]="gridState.columnFilters?.length == 0"
                    matBadgeColor="warn"
                    aria-hidden="false"
                >
                    filter_list
                </mat-icon>
                <span class="cdk-visually-hidden">
                    Filter icon showing active filters with a value of {{ gridState.columnFilters?.length }}
                </span>
            </button>

            <!-- columns configurator -->
            <button
                *ngIf="hasColumnsConfigPropertiesButton"
                mat-icon-button
                class="mr-2"
                type="button"
                (click)="handleColumnsConfigPropertiesDialog()"
            >
                <mat-icon
                    [matBadge]="'!'"
                    [matBadgeHidden]="gridState.columnsConfig | isOriginColumnConfig:originColumnsConfig()"
                    aria-hidden="false"
                >
                    table_chart
                </mat-icon>
            </button>

            <!-- right position custom header template -->
            <ng-container *ngFor="let gridCustomHeader of gridCustomHeadersTemplate | filterGridCustomHeaderTemplatesPositionPipe:'right'">
                <ng-container
                    *ngTemplateOutlet="gridCustomHeader.templateRef"
                ></ng-container>
            </ng-container>

        </div>

    </div>

    <div class="grid-content overflow-auto">
        <table
            mat-table
            matSort
            cdkDropList
            class="w-full flex-1"
            [dataSource]="gridData?.rows"
            [matSortActive]="gridState?.sort.active"
            [matSortDirection]="gridState?.sort.direction"
            [cdkDropListDisabled]="!hasDragAndDrop"
            [cdkDropListData]="gridData?.rows"
            (cdkDropListDropped)="rowDrop.emit($event)"
        >
            <ng-container *ngFor="let columnConfig of gridState.columnsConfig">

                <ng-container
                    [matColumnDef]="columnConfig.field"
                    [sticky]="columnConfig.sticky"
                >
                    @if (!columnConfig.hidden)
                    {
                        @switch (columnConfig.type)
                        {
                            <!-- STRING -->
                            @case (columnConfigType.STRING)
                            {
                                <th
                                    mat-header-cell
                                    *matHeaderCellDef
                                    [disabled]="!columnConfig.sort"
                                    [ngClass]="columnConfig.headerClass"
                                    [mat-sort-header]="columnConfig.sort"
                                >
                                    {{ columnConfig.field | gridTranslate:'column':id | async }}
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let row"
                                    [ngClass]="columnConfig.bodyClass"
                                >
                                    <!-- set value by template -->
                                    <div *ngFor="let cellValueTemplate of gridCellValuesTemplate">
                                        <div *ngIf="(gridCellValuesTemplate | hasCellValueWithFieldTemplate: columnConfig) && cellValueTemplate.field === columnConfig.field">
                                            <ng-container
                                                *ngTemplateOutlet="cellValueTemplate.templateRef; context: { $implicit: row | getCellValue:columnConfig.field, columnConfig, row }"
                                            >
                                            </ng-container>
                                        </div>
                                        <!-- set value by template for all fields -->
                                        <div *ngIf="!(gridCellValuesTemplate | hasCellValueWithFieldTemplate: columnConfig) && cellValueTemplate.field === undefined">
                                            <ng-container
                                                *ngTemplateOutlet="cellValueTemplate.templateRef; context: { $implicit: row | getCellValue:columnConfig.field, columnConfig, row }"
                                            >
                                            </ng-container>
                                        </div>
                                    </div>
                                    <!-- set value by columns config transform -->
                                    <div *ngIf="!(gridCellValuesTemplate | hasCellValueTemplate:columnConfig)">
                                        {{ row | getCellValue:columnConfig.field | transformDataCell:columnConfig.transform }}
                                    </div>
                                </td>
                            }

                            <!-- BOOLEAN -->
                            @case (columnConfigType.BOOLEAN)
                            {
                                <th
                                    mat-header-cell
                                    *matHeaderCellDef
                                    [disabled]="!columnConfig.sort"
                                    [ngClass]="columnConfig.headerClass"
                                    [mat-sort-header]="columnConfig.sort"
                                >
                                    {{ columnConfig.field | gridTranslate:'column':id | async }}
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let row"
                                    [ngClass]="columnConfig.bodyClass"
                                >
                                    <!-- set value by template -->
                                    <div *ngFor="let cellValueTemplate of gridCellValuesTemplate">
                                        <div *ngIf="(gridCellValuesTemplate | hasCellValueWithFieldTemplate: columnConfig) && cellValueTemplate.field === columnConfig.field">
                                            <ng-container
                                                *ngTemplateOutlet="cellValueTemplate.templateRef; context: { $implicit: row | getCellValue:columnConfig.field, columnConfig, row }"
                                            >
                                            </ng-container>
                                        </div>
                                        <!-- set value by template for all fields -->
                                        <div *ngIf="!(gridCellValuesTemplate | hasCellValueWithFieldTemplate: columnConfig) && cellValueTemplate.field === undefined">
                                            <ng-container
                                                *ngTemplateOutlet="cellValueTemplate.templateRef; context: { $implicit: row | getCellValue:columnConfig.field, columnConfig, row }"
                                            >
                                            </ng-container>
                                        </div>
                                    </div>
                                    <!-- set value by columns config transform -->
                                    <div *ngIf="!(gridCellValuesTemplate | hasCellValueTemplate:columnConfig)">
                                        <mat-icon *ngIf="row | getCellValue:columnConfig.field">done</mat-icon>
                                    </div>
                                </td>
                            }

                            <!-- NUMBER -->
                            @case (columnConfigType.NUMBER)
                            {
                                <th
                                    mat-header-cell
                                    *matHeaderCellDef
                                    [disabled]="!columnConfig.sort"
                                    [ngClass]="columnConfig.headerClass"
                                    [mat-sort-header]="columnConfig.sort"
                                >
                                    {{ columnConfig.field | gridTranslate:'column':id | async }}
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let row"
                                    [ngClass]="columnConfig.bodyClass"
                                >
                                    <!-- set value by template -->
                                    <div *ngFor="let cellValueTemplate of gridCellValuesTemplate">
                                        <div *ngIf="(gridCellValuesTemplate | hasCellValueWithFieldTemplate: columnConfig) && cellValueTemplate.field === columnConfig.field">
                                            <ng-container
                                                *ngTemplateOutlet="cellValueTemplate.templateRef; context: { $implicit: row | getCellValue:columnConfig.field, columnConfig, row }"
                                            >
                                            </ng-container>
                                        </div>
                                        <!-- set value by template for all fields -->
                                        <div *ngIf="!(gridCellValuesTemplate | hasCellValueWithFieldTemplate: columnConfig) && cellValueTemplate.field === undefined">
                                            <ng-container
                                                *ngTemplateOutlet="cellValueTemplate.templateRef; context: { $implicit: row | getCellValue:columnConfig.field, columnConfig, row }"
                                            >
                                            </ng-container>
                                        </div>
                                    </div>
                                    <!-- set value by columns config transform -->
                                    <div *ngIf="!(gridCellValuesTemplate | hasCellValueTemplate:columnConfig)">
                                        {{ row | getCellValue:columnConfig.field | transformDataCell:columnConfig.transform }}
                                    </div>
                                </td>
                            }

                            <!-- DATE -->
                            @case (columnConfigType.DATE)
                            {
                                <th
                                    mat-header-cell
                                    *matHeaderCellDef
                                    [disabled]="!columnConfig.sort"
                                    [ngClass]="columnConfig.headerClass"
                                    [mat-sort-header]="columnConfig.sort"
                                >
                                    {{ columnConfig.field | gridTranslate:'column':id | async }}
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let row"
                                    [ngClass]="columnConfig.bodyClass"
                                >
                                    <!-- set value by template -->
                                    <div *ngFor="let cellValueTemplate of gridCellValuesTemplate">
                                        <div *ngIf="(gridCellValuesTemplate | hasCellValueWithFieldTemplate: columnConfig) && cellValueTemplate.field === columnConfig.field">
                                            <ng-container
                                                *ngTemplateOutlet="cellValueTemplate.templateRef; context: { $implicit: row | getCellValue:columnConfig.field, columnConfig, row }"
                                            >
                                            </ng-container>
                                        </div>
                                        <!-- set value by template for all fields -->
                                        <div *ngIf="!(gridCellValuesTemplate | hasCellValueWithFieldTemplate: columnConfig) && cellValueTemplate.field === undefined">
                                            <ng-container
                                                *ngTemplateOutlet="cellValueTemplate.templateRef; context: { $implicit: row | getCellValue:columnConfig.field, columnConfig, row }"
                                            >
                                            </ng-container>
                                        </div>
                                    </div>
                                    <!-- set value by columns config transform -->
                                    <div *ngIf="!(gridCellValuesTemplate | hasCellValueTemplate:columnConfig)">
                                        {{ row | getCellValue:columnConfig.field | transformDataCell:columnConfig.transform }}
                                    </div>
                                </td>
                            }

                            <!-- ENUM -->
                            @case (columnConfigType.ENUM)
                            {
                                <th
                                    mat-header-cell
                                    *matHeaderCellDef
                                    [disabled]="!columnConfig.sort"
                                    [ngClass]="columnConfig.headerClass"
                                    [mat-sort-header]="columnConfig.sort"
                                >
                                    {{ columnConfig.field | gridTranslate:'column':id | async }}
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let row"
                                    [ngClass]="columnConfig.bodyClass"
                                >
                                    <!-- set value by template -->
                                    <div *ngFor="let cellValueTemplate of gridCellValuesTemplate">
                                        <div *ngIf="(gridCellValuesTemplate | hasCellValueWithFieldTemplate: columnConfig) && cellValueTemplate.field === columnConfig.field">
                                            <ng-container
                                                *ngTemplateOutlet="cellValueTemplate.templateRef; context: { $implicit: row | getCellValue:columnConfig.field, columnConfig, row }"
                                            >
                                            </ng-container>
                                        </div>
                                        <!-- set value by template for all fields -->
                                        <div *ngIf="!(gridCellValuesTemplate | hasCellValueWithFieldTemplate: columnConfig) && cellValueTemplate.field === undefined">
                                            <ng-container
                                                *ngTemplateOutlet="cellValueTemplate.templateRef; context: { $implicit: row | getCellValue:columnConfig.field, columnConfig, row }"
                                            >
                                            </ng-container>
                                        </div>
                                    </div>
                                    <!-- set value by columns config transform -->
                                    <div *ngIf="!(gridCellValuesTemplate | hasCellValueTemplate:columnConfig)">
                                        {{ row | getCellValue:columnConfig.field | transformDataCell:columnConfig.transform }}
                                    </div>
                                </td>
                            }

                            <!-- ARRAY -->
                            @case (columnConfigType.ARRAY)
                            {
                                <th
                                    mat-header-cell
                                    *matHeaderCellDef
                                    [disabled]="!columnConfig.sort"
                                    [ngClass]="columnConfig.headerClass"
                                    [mat-sort-header]="columnConfig.sort"
                                >
                                    {{ columnConfig.field | gridTranslate:'column':id | async }}
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let row"
                                    [ngClass]="columnConfig.bodyClass"
                                >
                                    <!-- set value by template -->
                                    <div *ngFor="let cellValueTemplate of gridCellValuesTemplate">
                                        <div *ngIf="(gridCellValuesTemplate | hasCellValueWithFieldTemplate: columnConfig) && cellValueTemplate.field === columnConfig.field">
                                            <ng-container
                                                *ngTemplateOutlet="cellValueTemplate.templateRef; context: { $implicit: row | getCellValue:columnConfig.field, columnConfig, row }"
                                            >
                                            </ng-container>
                                        </div>
                                        <!-- set value by template for all fields -->
                                        <div *ngIf="!(gridCellValuesTemplate | hasCellValueWithFieldTemplate: columnConfig) && cellValueTemplate.field === undefined">
                                            <ng-container
                                                *ngTemplateOutlet="cellValueTemplate.templateRef; context: { $implicit: row | getCellValue:columnConfig.field, columnConfig, row }"
                                            >
                                            </ng-container>
                                        </div>
                                    </div>
                                    <!-- set value by columns config transform -->
                                    <div *ngIf="!(gridCellValuesTemplate | hasCellValueTemplate:columnConfig)">
                                        {{ row | getCellValue:columnConfig.field | transformDataCell:columnConfig.transform | join }}
                                    </div>
                                </td>
                            }

                            <!-- JSONB -->
                            @case (columnConfigType.JSONB)
                            {
                                <th
                                    mat-header-cell
                                    *matHeaderCellDef
                                    [disabled]="!columnConfig.sort"
                                    [ngClass]="columnConfig.headerClass"
                                    [mat-sort-header]="columnConfig.sort"
                                >
                                    {{ columnConfig.field | gridTranslate:'column':id | async }}
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let row"
                                    [ngClass]="columnConfig.bodyClass"
                                >
                                    <!-- set value by template -->
                                    <div *ngFor="let cellValueTemplate of gridCellValuesTemplate">
                                        <div *ngIf="(gridCellValuesTemplate | hasCellValueWithFieldTemplate: columnConfig) && cellValueTemplate.field === columnConfig.field">
                                            <ng-container
                                                *ngTemplateOutlet="cellValueTemplate.templateRef; context: { $implicit: row | getCellValue:columnConfig.field, columnConfig, row }"
                                            >
                                            </ng-container>
                                        </div>
                                        <!-- set value by template for all fields -->
                                        <div *ngIf="!(gridCellValuesTemplate | hasCellValueWithFieldTemplate: columnConfig) && cellValueTemplate.field === undefined">
                                            <ng-container
                                                *ngTemplateOutlet="cellValueTemplate.templateRef; context: { $implicit: row | getCellValue:columnConfig.field, columnConfig, row }"
                                            >
                                            </ng-container>
                                        </div>
                                    </div>
                                    <!-- set value by columns config transform -->
                                    <div *ngIf="!(gridCellValuesTemplate | hasCellValueTemplate:columnConfig)">
                                        {{ row | getCellValue:columnConfig.field | transformDataCell:columnConfig.transform }}
                                    </div>
                                </td>
                            }

                            <!-- TRANSLATIONS_MENU -->
                            @case (columnConfigType.TRANSLATIONS_MENU)
                            {
                                <th
                                    mat-header-cell
                                    *matHeaderCellDef
                                    [disabled]="!columnConfig.sort"
                                    [ngClass]="columnConfig.headerClass"
                                    [mat-sort-header]="columnConfig.sort"
                                >
                                    {{ columnConfig.field | gridTranslate:'column':id | async }}
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let row"
                                    [ngClass]="columnConfig.bodyClass"
                                >
                                    <div class="flex justify-center">
                                        <button
                                            mat-icon-button
                                            [matMenuTriggerFor]="menu"
                                            [color]="columnConfig.translationIconColor | getTranslationIconColor:row"
                                        >
                                            <mat-icon svgIcon="mat_solid:translate"></mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <ng-container *ngFor="let action of columnConfig.actions | getActions:row">
                                                <button
                                                    mat-menu-item
                                                    type="button"
                                                    (click)="handleClickAction(action, row, $event)"
                                                >
                                                    <au-flag-icon
                                                        [flagCode]="action.meta.lang.image"
                                                        fontSize="16px"
                                                    >
                                                        {{ (action.translation ? action.translation : action.id) | gridTranslate:'action':id | async }}
                                                    </au-flag-icon>
                                                </button>
                                            </ng-container>
                                        </mat-menu>
                                    </div>
                                </td>
                            }

                            <!-- ACTIONS -->
                            @case (columnConfigType.ACTIONS)
                            {
                                <th
                                    mat-header-cell
                                    *matHeaderCellDef
                                    [ngClass]="columnConfig.headerClass ? columnConfig.headerClass : 'w-20 text-center'"
                                >
                                    {{ columnConfig.field | gridTranslate:'column':id | async }}
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let row"
                                    [ngClass]="columnConfig.bodyClass ? columnConfig.bodyClass : 'w-20 text-center'"
                                >
                                    <!-- print action menu if has more than one action -->
                                    <ng-container *ngIf="(columnConfig.actions | getActions:row).length > 1">
                                        <button
                                            mat-icon-button
                                            type="button"
                                            [matMenuTriggerFor]="menu"
                                        >
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <ng-container *ngFor="let action of columnConfig.actions | getActions:row">
                                                <button
                                                    mat-menu-item
                                                    type="button"
                                                    [disabled]="action.spinner | getGridSpinnerFlag | async"
                                                    (click)="handleClickAction(action, row, $event)"
                                                >
                                                    <mat-icon
                                                        *ngIf="!(action.spinner | getGridSpinnerFlag | async)"
                                                        [svgIcon]="action.svgIcon"
                                                        [fontSet]="action.iconFontSet"
                                                        [matBadgeHidden]="action.badge?.hidden"
                                                        [matBadgeColor]="action.badge?.color"
                                                        [matBadge]="action.badge?.label"
                                                        aria-hidden="false"
                                                    >
                                                        {{ action.icon }}
                                                    </mat-icon>
                                                    <mat-icon *ngIf="action.spinner | getGridSpinnerFlag | async">
                                                        <mat-spinner diameter="20"></mat-spinner>
                                                    </mat-icon>
                                                    <span>{{ (action.translation ? action.translation : action.id) | gridTranslate:'action':id | async }}</span>
                                                </button>
                                            </ng-container>
                                        </mat-menu>
                                    </ng-container>
                                    <!-- if only have one action, print action without menu -->
                                    <ng-container *ngIf="(columnConfig.actions | getActions:row).length === 1">
                                        <ng-container *ngFor="let action of columnConfig.actions | getActions:row">
                                            <button
                                                mat-icon-button
                                                type="button"
                                                [disabled]="action.spinner | getGridSpinnerFlag | async"
                                                (click)="handleClickAction(action, row, $event)"
                                            >
                                                <mat-icon
                                                    *ngIf="!(action.spinner | getGridSpinnerFlag | async)"
                                                    [svgIcon]="action.svgIcon"
                                                    [fontSet]="action.iconFontSet"
                                                    [matBadgeHidden]="action.badge?.hidden"
                                                    [matBadgeColor]="action.badge?.color"
                                                    [matBadge]="action.badge?.label"
                                                    aria-hidden="false"
                                                >
                                                    {{ action.icon }}
                                                </mat-icon>
                                                <mat-icon *ngIf="action.spinner | getGridSpinnerFlag | async">
                                                    <mat-spinner diameter="20"></mat-spinner>
                                                </mat-icon>
                                            </button>
                                        </ng-container>
                                    </ng-container>
                                </td>
                            }

                            <!-- CHECKBOX -->
                            @case (columnConfigType.CHECKBOX)
                            {
                                <th
                                    mat-header-cell
                                    *matHeaderCellDef
                                    [ngClass]="columnConfig.headerClass ? columnConfig.headerClass : 'w-20 text-center'"
                                >
                                    <mat-checkbox
                                        (change)="$event ? masterToggle() : null"
                                        [checked]="selectedCheckboxRowModel.hasValue() && isAllSelected()"
                                        [indeterminate]="isSomeSelected() && !isAllSelected()"
                                        [aria-label]="checkboxLabel()"
                                    >
                                    </mat-checkbox>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let row"
                                    [ngClass]="columnConfig.bodyClass ? columnConfig.bodyClass : 'w-20 text-center'"
                                >
                                    <mat-checkbox
                                        (click)="$event.stopPropagation()"
                                        (change)="$event ? selectedCheckboxRowModel.toggle(row) : null"
                                        [checked]="selectedCheckboxRowModel.isSelected(row)"
                                        [aria-label]="checkboxLabel(row)"
                                    >
                                    </mat-checkbox>
                                </td>
                            }

                            <!-- DRAG AND DROP -->
                            @case (columnConfigType.DRAG_AND_DROP)
                            {
                                <th
                                    mat-header-cell
                                    *matHeaderCellDef
                                    [ngClass]="columnConfig.headerClass ? columnConfig.headerClass : 'w-20 text-center'"
                                >
                                    {{ columnConfig.field | gridTranslate:'column':id | async }}
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let row"
                                    [ngClass]="columnConfig.bodyClass ? columnConfig.bodyClass : 'w-20 text-center'"
                                >
                                    <mat-icon
                                        cdkDragHandle
                                        class="cursor-move"
                                    >
                                        reorder
                                    </mat-icon>
                                </td>
                            }
                        }
                    }
                </ng-container>

            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
            >
            </tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns;"
                cdkDrag
                [cdkDragData]="row"
            >
            </tr>

            <tr class="mat-row" *matNoDataRow>
                <td
                    class="mat-cell"
                    [attr.colspan]="displayedColumns?.length"
                >
                    <p class="p-2 text-base">
                        {{ 'noData' | gridTranslate:'message':gridId | async }}
                    </p>
                </td>
            </tr>

        </table>
    </div>

    <div class="grid-footer">
        @if (hasPagination)
        {
            <mat-paginator
                showFirstLastButtons
                class="rounded-b-md"
                aria-label="Select page of periodic elements"
                (page)="handlePageStateChange($event)"
                [length]="gridData?.count"
                [pageIndex]="gridState?.page?.pageIndex"
                [pageSize]="gridState?.page?.pageSize"
                [pageSizeOptions]="[10, 20, 50, 100]"
            >
            </mat-paginator>
        }
        @else
        {
            <div class="h-3"></div>
        }
    </div>
</div>
