export const environment = {
    production: false,
    debug     : true,
    api       : {
        graphql: 'https://digitai-dev-api.beopen.cloud/graphql',
        rest   : 'https://digitai-dev-api.beopen.cloud',
    },
    lang: {
        base    : 'es',
        fallback: 'es',
        langs   : ['es','en'],
    },
    oAuth: {
        applicationCode  : 'digitai',
        applicationSecret: 'CrSoO&amp;eO7h8gkUED@Xxdgh7AFiBCnthEld2z6S$RC~rt1bgg',
    },
    appearance: {
        theme : 'theme-default',
        layout: 'classy',
    }
};
