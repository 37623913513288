{
    "name": "itp-digitai-aurora-front",
    "version": "1.1.19",
    "description": "ITP Digitai front dashboard",
    "author": "José Carlos Rodríguez Palacín <carlos.rodriguez.palacin@gmail.com>",
    "private": true,
    "license": "MIT",
    "scripts": {
        "ng": "ng",
        "start": "ng serve",
        "start:dev": "node ./node_modules/@angular/cli/bin/ng serve --configuration=development",
        "start:local": "node ./node_modules/@angular/cli/bin/ng serve --configuration=localhost",
        "build": "ng build",
        "watch": "ng build --watch --configuration development",
        "test": "ng test"
    },
    "dependencies": {
        "@angular/animations": "18.0.1",
        "@angular/cdk": "18.0.1",
        "@angular/common": "18.0.1",
        "@angular/compiler": "18.0.1",
        "@angular/core": "18.0.1",
        "@angular/forms": "18.0.1",
        "@angular/material": "18.0.1",
        "@angular/material-luxon-adapter": "18.0.1",
        "@angular/platform-browser": "18.0.1",
        "@angular/platform-browser-dynamic": "18.0.1",
        "@angular/router": "18.0.1",
        "@apollo/client": "^3.10.6",
        "@ng-matero/extensions": "^18.0.2",
        "@ngneat/transloco": "6.0.4",
        "@rxweb/reactive-form-validators": "^13.0.1",
        "apexcharts": "3.49.1",
        "apollo-angular": "^7.0.2",
        "apollo-upload-client": "^18.0.1",
        "cropperjs": "^1.6.2",
        "crypto-js": "4.2.0",
        "dayjs": "^1.11.11",
        "file-saver": "^2.0.5",
        "flag-icons": "^7.2.3",
        "generate-password-browser": "^1.1.0",
        "graphql": "^16.9.0",
        "highlight.js": "11.9.0",
        "libphonenumber-js": "^1.11.4",
        "lodash-es": "4.17.21",
        "luxon": "3.4.4",
        "ng-apexcharts": "1.10.0",
        "ngx-file-drop": "^16.0.0",
        "ngx-json-viewer": "^3.2.1",
        "ngx-mat-select-search": "^7.0.6",
        "ngx-quill": "26.0.1",
        "perfect-scrollbar": "1.5.5",
        "quill": "2.0.2",
        "rxjs": "7.8.1",
        "swiper": "^11.1.12",
        "tslib": "2.6.2",
        "xlsx": "^0.18.5",
        "zone.js": "0.14.6"
    },
    "devDependencies": {
        "@angular-devkit/build-angular": "18.0.2",
        "@angular/cli": "18.0.2",
        "@angular/compiler-cli": "18.0.1",
        "@tailwindcss/typography": "0.5.13",
        "@types/chroma-js": "2.4.4",
        "@types/crypto-js": "4.2.2",
        "@types/file-saver": "^2.0.7",
        "@types/highlight.js": "10.1.0",
        "@types/jasmine": "5.1.4",
        "@types/lodash": "4.17.4",
        "@types/lodash-es": "4.17.12",
        "@types/luxon": "3.4.2",
        "autoprefixer": "10.4.19",
        "chroma-js": "2.4.2",
        "jasmine-core": "5.1.2",
        "karma": "6.4.3",
        "karma-chrome-launcher": "3.2.0",
        "karma-coverage": "2.2.1",
        "karma-jasmine": "5.1.0",
        "karma-jasmine-html-reporter": "2.1.0",
        "lodash": "4.17.21",
        "postcss": "8.4.38",
        "prettier": "3.3.0",
        "prettier-plugin-organize-imports": "3.2.4",
        "prettier-plugin-tailwindcss": "0.6.1",
        "tailwindcss": "3.4.3",
        "typescript": "5.4.5"
    }
}
