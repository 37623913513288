<!--
Header close button, deprecated
<div>
    <button mat-dialog-close mat-icon-button class="absolute top-2 right-2">
        <mat-icon>close</mat-icon>
    </button>
</div>
-->

<div mat-dialog-content class="flex flex-col h-full">
    <div
        class="flex flex-auto h-full overflow-y-auto pt-0"
        cdkScrollable
    >
        <!-- grid -->
        <au-grid
            [id]="gridId"
            [columnsConfig]="columnsConfig$ | async"
            [gridData]="gridData$ | async"
            [gridState]="data.gridState"
            [originColumnsConfig]="data.originColumnsConfig"
            [selectedCheckboxRowModel]="selectedCheckboxRowModel"
            [selectedRows]="selectedRows"
            (action)="action.emit($event)"
            (columnsConfigChange)="columnsConfigChange.emit($event)"
            (columnFiltersChange)="columnFiltersChange.emit($event)"
            (selectedCheckboxRowModelChange)="selectedCheckboxRowModelChange.emit($event)"
            (stateChange)="stateChange.emit($event)"
            (searchOpen)="searchOpen.emit()"
            (searchClose)="searchClose.emit()"
            (search)="search.emit($event)"
        >
            <ng-container *ngTemplateOutlet="data.gridTranslations?.templateRef">
            </ng-container>

            <!-- container to pass GridCustomHeaderTemplateDirective -->
            @for (gridCustomHeaderTemplate of data.gridCustomHeadersTemplate; track gridCustomHeaderTemplate.position)
            {
                <ng-template
                    auGridCustomHeaderTemplate
                    [position]="gridCustomHeaderTemplate.position"
                >
                    <ng-container *ngTemplateOutlet="gridCustomHeaderTemplate.templateRef">
                    </ng-container>
                    @if (gridCustomHeaderTemplate.position === 'right')
                    {
                        <button
                            mat-icon-button
                            mat-dialog-close
                            class="mr-2"
                            type="button"
                        >
                            <mat-icon aria-hidden="false">
                                close
                            </mat-icon>
                        </button>
                    }
                </ng-template>
            }

            @if (!hasCustomHeadersRightPosition())
            {
                <ng-template
                    auGridCustomHeaderTemplate
                    position="right"
                >
                    <button
                        mat-icon-button
                        mat-dialog-close
                        class="mr-2"
                        type="button"
                    >
                        <mat-icon aria-hidden="false">
                            close
                        </mat-icon>
                    </button>
                </ng-template>
            }

            <!-- container to pass GridCellValueTemplateDirective -->
            @for (gridCellValueTemplate of data.gridCellValuesTemplate; track gridCellValueTemplate.field)
            {
                <ng-template
                    auGridCellValueTemplate
                    let-value
                    let-columnConfig="columnConfig"
                    let-row="row"
                    [field]="gridCellValueTemplate.field"
                >
                    <ng-container *ngTemplateOutlet="gridCellValueTemplate.templateRef; context: { $implicit: value, columnConfig, row }">
                    </ng-container>
                </ng-template>
            }

        </au-grid>
        <!-- / grid -->
    </div>
</div>